

    $app-env: "prod";
    $instance-id: "regiosuisse";
    $instance-name: "regiosuisse Tools";
    $theme-color-1: #B4BE00;
    $theme-color-2: #73890d;
    $theme-color-3: #D3E292;
    $theme-color-4: #404241;



.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; 
  align-items: center; 
  justify-content: center; 
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  background: #fff;
  width: 90%;
  max-width:50vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-header {
  padding: 16px;
  background: #f5f5f5;
  flex: 0 0 auto; 
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #ddd;
  flex-direction: column;

  h3 {
    margin: 0;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding: 16px;
  flex: 1 1 auto; 
  overflow-y: auto; 
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-footer {
  padding: 16px;
  background: #f5f5f5;
  flex: 0 0 auto;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
  border-radius: 4px;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

table th,
table td {
  padding: 12px 15px;
}

table th {
  background-color: #b4be00;
  color: white;
}

.email-container {
    background-color: #ffffff;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.email-header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    max-width: 180px;
    height: auto;
    margin-bottom: 2em;
}

.email-content {
    font-size: 16px;
    line-height: 1.6;
}

.email-content p {
    margin-bottom: 15px;
}

.email-content a {
    color: #B4BE00;
    text-decoration: none;
    font-weight: bold;
}

.email-footer {
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
    text-align: center;
}
