$max-width: 1220px !default;
$gutter-width: 2em !default;
$primary-color: $theme-color-2 !default;
$secondary-color: $theme-color-1 !default;
$black: $black !default;
$white: $white !default;
$grey-ultralight: #EFEFEF !default;
$grey-light: #d9d9d9 !default;
$grey-mid: #979797 !default;
$grey-dark: #333333 !default;
$grey-ultradark: #212121 !default;